import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material';

import ProtectedRoute from './components/ProtectedRoute'
import Layout from './hocs/Layout';

import { Provider } from 'react-redux';
import { store } from './store'

import Login from './pages/Login';
import Accounts from './pages/client/Accounts'
import AccountDetails from './pages/client/AccountDetails'
import Claims from './pages/client/Claims'
import ClaimDetails from './pages/client/ClaimDetails'
import SubmitClaim from './pages/client/SubmitClaim'
import NotFound from './pages/NotFound'
import Quotes from './pages/client/Quotes'
import GetQuote from './pages/client/GetQuote'
import GetQuotePublic from './pages/client/GetQuotePublic'
import Coverages from './pages/broker/Coverages'
import BrokerAccountDetails from './pages/broker/BrokerAccountDetails'
import CoverageDetails from './pages/broker/CoverageDetails'
import SubmitLiabilityQuote from './pages/broker/SubmitLiabilityQuote';
//eslint-disable-next-line
const theme = createTheme({
  palette: {
      primary: {
          main: '#FF920C',
      },
      // secondary: {
      //     main: '#bf360c',
      // },
  },
});

const App = () => (
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path='*' element={<Login />} />
            {/* <Route path='/accounts' element={<ProtectedRoute allowed_group='client'> <Accounts /> </ProtectedRoute>} />
            <Route path='/accounts/:id' element={<ProtectedRoute allowed_group='client'> <AccountDetails /> </ProtectedRoute>} />
            <Route path='/accounts/:id/submit-claim' element={<ProtectedRoute allowed_group='client'> <SubmitClaim /> </ProtectedRoute>} />
            <Route path='/claims' element={<ProtectedRoute allowed_group='client'> <Claims /> </ProtectedRoute>} />
            <Route path='/claims/:id' element={<ProtectedRoute allowed_group='client'> <ClaimDetails /> </ProtectedRoute>} />
            <Route path='/quotes' element={<ProtectedRoute allowed_group='client'> <Quotes /> </ProtectedRoute>} />
            <Route path='/instant-quote-tool' element={<GetQuotePublic />} />
            <Route path='/get-quote' element={<ProtectedRoute allowed_group='client'> <GetQuote /> </ProtectedRoute>} />


            <Route path='/broker/coverages' element={<ProtectedRoute allowed_group='broker'> <Coverages/> </ProtectedRoute>} />
            <Route path='/broker/accounts/:id' element={<ProtectedRoute allowed_group='broker'> <BrokerAccountDetails/> </ProtectedRoute>} />
            <Route path='/broker/coverages/gl/:id' element={<ProtectedRoute allowed_group='broker'> <CoverageDetails/> </ProtectedRoute>} />
            <Route path='/broker/coverages/umbrella/:id' element={<ProtectedRoute allowed_group='broker'> <CoverageDetails/> </ProtectedRoute>} />
            <Route path='/submit-liability-quote/gl/:id' element={<ProtectedRoute allowed_group='broker'> <SubmitLiabilityQuote/> </ProtectedRoute>} />
            <Route path='/submit-liability-quote/umbrella/:id' element={<ProtectedRoute allowed_group='broker'> <SubmitLiabilityQuote/> </ProtectedRoute>} />
            <Route path='*' element={<ProtectedRoute> <NotFound /> </ProtectedRoute>} /> */}
          </Routes>
        </Layout>
      </Router>
    </Provider>
  </ThemeProvider>
)


export default App;
