import React from 'react';
// import React, { useState } from 'react';
// import { Link, Navigate, useLocation } from 'react-router-dom';
// import { useDispatch, useSelector } from 'react-redux';
// import { TextField, Button, Typography, Select, MenuItem } from '@mui/material';


// import {
//     login
// } from '../slices/authSlice'

const Login = () => {
    // const isAuthenticated = false;
    // const dispatch = useDispatch();
    // const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
    // const isBroker = useSelector((state) => state.auth.user?.user_groups?.includes('broker'))
    // const isClient = useSelector((state) => state.auth.user?.user_groups?.includes('client'))

    // const [formData, setFormData] = useState({
    //     userRole: '',
    //     email : '',
    //     password: ''
    // });

    // const { userRole, email, password } = formData

    // const onChange = e => setFormData({...formData, [e.target.name] : e.target.value})
    // const onSubmit = e => {
    //     e.preventDefault();

    //     dispatch(login({email, password}))
    // }
    
    // if (isAuthenticated){
    //     return <Navigate to={isBroker ? '/broker/coverages/expiring' : '/accounts'} />
    // }

    const styles = {
        container: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width: '100%',
          backgroundColor: 'white',
        //   backgroundImage: 'url(SIGLogo.png)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          color: 'black',
          textAlign: 'center',
          padding: '0 20px',
          boxSizing: 'border-box',
        },
        overlay: {
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: -1,
        },
        heading: {
          fontSize: '3rem',
          marginBottom: '20px',
        },
        message: {
          fontSize: '1.5rem',
          marginBottom: '30px',
        },
      };
    
      return (
        <div style={styles.container}>
          {/* <div style={styles.overlay}></div> */}
          {/* <img src='SIGLogo.png' alt='SIG Logo'></img> */}
          <h1 style={styles.heading}>🚧 Under Construction 🚧</h1>
          <p style={styles.message}>We're working hard to bring you a better experience. Stay tuned!</p>
          <p style={styles.message}> <a href='https://strategicinsurer.typeform.com/InstantQuote'>Try out our instant quote tool</a></p>
          {/* <div style={styles.spinner}></div> */}
          {/* Inline CSS for keyframes */}
          {/* <style>
            {`
              @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
              }
            `}
          </style> */}
        </div>
      );

    // return (
        // <div style={{display: 'flex', height: '100%', width: '100%'}}>
        //     <div style={{display: 'flex', height: '100%', width: '100%', textAlign: 'center', justifyContent: 'center', alignContent: 'center', fontSize: 'x-large'}}>
        //         Under Construction
        //     </div>
        // </div>
        // <div className='container mt-5'>
        //     <Typography variant="h4" gutterBottom style={{paddingBottom: "2%"}} data-testid="welcome-heading">
        //         Welcome!
        //     </Typography>
        //     <form onSubmit={e => onSubmit(e)} >
        //         <div style={{display: 'flex', flexDirection: 'column'}}>
        //         <div className='form-group' >
        //             <Select
        //                 value={userRole}
        //                 label='Log in as'
        //                 onChange={e => onChange(e)}
        //             >
        //                 <MenuItem value={'client'}>Client</MenuItem>
        //                 <MenuItem value={'broker'}>Broker</MenuItem>
        //             </Select>
        //             </div>
        //             <div className='form-group' style={{paddingTop: "1%", paddingBottom: "1%"}}>
                        
        //                 <TextField
        //                     style={{width: "30%"}}
        //                     id="outlined-email-input"
        //                     label="Email"
        //                     type="email"
        //                     name="email"
        //                     // value={email}
        //                     onChange={e => onChange(e)}
        //                     required
        //                     inputProps={{ 'data-testid': 'login-email-input' }}
        //                 />
        //                 {/* <input
        //                     className='form-control'
        //                     type='email'
        //                     placeholder='Email'
        //                     name='email'
        //                     value={email}
        //                     onChange={e => onChange(e)}
        //                     required
        //                 /> */}
        //             </div>

        //             <div className='form-group' style={{paddingTop: "1%", paddingBottom: "1%"}}>
        //                 <TextField
        //                     style={{width: "30%"}}
        //                     id="outlined-password-input"
        //                     label="Password"
        //                     type="password"
        //                     name="password"
        //                     autoComplete="current-password"
        //                     // value={password}
        //                     onChange={e => onChange(e)}
        //                     required
        //                     inputProps={{ 'data-testid': 'login-password-input' }}
        //                 />
        //                 {/* <input
        //                     className='form-control'
        //                     type='password'
        //                     placeholder='Password'
        //                     name='password'
        //                     value={password}
        //                     onChange={e => onChange(e)}
        //                     minLength='6'
        //                     required
        //                 /> */}
        //             </div>
        //         </div>
        //         {/* <button className='btn btn-primary' type='submit'>Login</button> */}

        //         <Button variant="contained" type="submit" style={{marginTop: "0.5%"}} data-testid='login-submit-button'>Log in</Button>
        //     </form>

        //     <p className='mt-3'>
        //         Forgot your password? <Link to='/reset-password'>Reset Password</Link>
        //     </p>
        // </div>
    // )
}

export default Login

// const mapStateToProps = state => ({
//     isAuthenticated: state.auth.isAuthenticated
// })

// export default connect(mapStateToProps, { login })(Login)